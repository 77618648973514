














import LabSim from '@/tasks/components/simulations/LabSim.vue';
import {defineComponent, PropType} from '@vue/composition-api';

export enum Variation {
  Upei = 0,
  Windsor = 1,
}

interface TitrationInputs {
  conNaOH: number;
  conHCL: number;
  conMgOH2: number;
  massMgOH2: number;
}

export default defineComponent({
  name: 'LabTitration',
  components: {LabSim},
  inheritAttrs: false,
  props: {
    variation: {
      type: Number as PropType<Variation>,
      default: Variation.Upei,
      validator(value: Variation): boolean {
        return [Variation.Upei, Variation.Windsor].includes(value);
      },
    },
    concNaoh: {
      type: Number as PropType<number>,
      default: 0.15,
    },
    concHcl: {
      type: Number as PropType<number>,
      default: 0.2,
    },
    concMgoh2: {
      type: Number as PropType<number>,
      default: 77.5,
    },
    massMgoh2: {
      type: Number as PropType<number>,
      default: 1.5,
    },
  },
  computed: {
    labData(): TitrationInputs {
      return {
        conNaOH: this.concNaoh,
        conHCL: this.concHcl,
        conMgOH2: this.concMgoh2,
        massMgOH2: this.massMgoh2,
      };
    },
    stringLabData(): string {
      return JSON.stringify(this.labData);
    },
  },
});
